import  {React,Component}  from "react";
import "../../config";
import ReactHtmlParser from 'react-html-parser';
import OtherBaab from "../sarf_components/other_baab";
import { createBrowserHistory } from "history";
const history = createBrowserHistory()
console.log( history.location.search);

class Sarf extends Component {
  
    constructor(props) {
        super(props);
        this.state = { 
            wordRecord: "",
            allBaabs:"",
            baab_code:'',
            baab_code2:'',
            muzaaray:'',
            amar:'',
            faail:'',
            mafool:'',
            masdar:'',
            ism_e_mubaalgha:'',
            seegha_mushabba:'',
            maazi:'',
            meaning:'',
            allTraditionalBaabs:'',
            ttbaab2:'',
            ttbaab:'',
            rootText:'',
            allNounTpDescs:'',
            allTensesDescs:'',
            tsarfitashreeh:'',
            noun_type_id:'',
            tense:'',
            word_state:'',
            number:'',
            gender:'',
            moreRelatedRecords:'',
            ayatBeforeWordArray:'' ,
            ayatAfterWordArray:'',
            ayatPageArray:'',
            ayatSurahArray:'',
            ayatNumberArray:'',
            transArray:''
        }
      
  
    }

    componentDidUpdate(){
         
        Object.keys(this.state.allBaabs).forEach( 
            (itemKey) => { 
                            

            if(this.state.allBaabs[itemKey].baab_code == this.state.baab_code){

                this.setState({baab_code:this.state.allBaabs[itemKey].name})
                var ttbaab = this.getTraditionalBaab(this.state.allBaabs[itemKey].name, this.state.allBaabs[itemKey].traditional_baab_id, this.state.allTraditionalBaabs);
                this.setState({ttbaab:ttbaab})
            }

            if(this.state.allBaabs[itemKey].baab_code == this.state.baab_code2){
                this.setState({baab_code2:this.state.allBaabs[itemKey].name})
                var ttbaab2 = this.getTraditionalBaab(this.state.allBaabs[itemKey].name, this.state.allBaabs[itemKey].traditional_baab_id, this.state.allTraditionalBaabs);
                this.setState({ttbaab2:ttbaab2})
            }
        })



    }
    getTraditionalBaab(wordBaab, givenTraditionalBaabID, allTraditionalBaabs){
        var traditionalBaab = "";
        if(givenTraditionalBaabID != ""){
            Object.keys(allTraditionalBaabs).forEach( 
                (itemKey) => { 
                if(allTraditionalBaabs[itemKey].id == givenTraditionalBaabID){
                    traditionalBaab = allTraditionalBaabs[itemKey].name;
                   // break;
                }
            });

            if(wordBaab !=traditionalBaab && traditionalBaab != ""){
                traditionalBaab = "(" + traditionalBaab + ")";
            } else { traditionalBaab = ""; }
        }
        return traditionalBaab;
    }

    getSpaceSeparatedChars(multilingualString) {
        var multilingualString = this.state.rootText.trim();
        if(multilingualString){
        var stop = multilingualString.length;
        var result = '';

        for (var idx = 0; idx < stop;) {
            if(multilingualString.substr(idx, 1) === ' ') {
                idx = idx + 1;
            } else {
                result = result + multilingualString.substr(idx, 2) + ' ';
                idx = idx + 2;
            }
        }

        return result;
    }
    }

    componentWillMount() {
        var id = this.props.computedMatch.params.id;  
    fetch(global.config.API.URL+"/api/sarf?id="+id)
    .then(res => res.json())
    .then(res => {
        this.setState({ 
        verbRecord:res.verbRecord,
        wordRecord: res.wordRecord,
        allNounTpDescs:res.allNounTpDescs,
        allTensesDescs:res.allTensesDescs,
        rootText:res.wordRecord.root_text,
        allBaabs:res.allBaabs,
        baab_code:res.wordRecord.baab_code,
        baab_code2:res.wordRecord.baab_code2 ,
        muzaaray:res.verbRecord.muzaaray,
        amar:res.verbRecord.amar,
        faail:res.verbRecord.faail,
        mafool:res.verbRecord.mafool,
        masdar:res.verbRecord.masdar,
        ism_e_mubaalgha:res.verbRecord.ism_e_mubaalgha,
        seegha_mushabba:res.verbRecord.seegha_mushabba,
        maazi:res.verbRecord.maazi,
        meaning:res.wfwResult.meanings_2,
        allTraditionalBaabs:res.allTraditionalBaabs,
        noun_type_id:res.wordRecord.noun_type_id,
        tense:res.wordRecord.tense,
        word_state:res.wordRecord.word_state,
        number:res.wordRecord.number,
        gender:res.wordRecord.gender,
        moreRelatedRecords:res.moreRelatedRecords,
        ayatBeforeWordArray:res.ayatBeforeWordArray ,
        ayatAfterWordArray:res.ayatAfterWordArray,
        ayatPageArray:res.ayatPageArray,
        ayatSurahArray:res.ayatSurahArray,
        ayatNumberArray:res.ayatNumberArray,
        transArray:res.transArray
        
    });
    this.settsarfitashreeh()
});
    

  //  setTimeout(this.settsarfitashreeh(),20);
    
 }
 insertCharAtEnd($stringToUpdate, $charToInsert) {
     
console.log($stringToUpdate);
    if ($stringToUpdate != "" && $stringToUpdate.substr(-1) != $charToInsert) {
        $stringToUpdate = $stringToUpdate + $charToInsert;
    }
    return $stringToUpdate;
}
 settsarfitashreeh(){
     console.log(this.state.tense+'== '+this.state.word_state+'== '+this.state.number+'== '+this.state.gender);
    var $wordNounType = ((this.state.noun_type_id != "") ? this.state.noun_type_id : "");
    var $wordTense = ((this.state.tense != "") ? this.state.tense  : "");
    var $wordState = ((this.state.word_state != "") ? this.state.word_state : "");
    var $wordNumber = ((this.state.number != "") ? this.state.number  : "");
    var $wordGender = ((this.state.gender != "") ? this.state.gender : "-");
    console.log($wordNounType,$wordTense,$wordState,$wordNumber,$wordGender)
    var $Who ='';
    if($wordTense != "" && $wordTense != "0"){
         
        Object.keys(this.state.allTensesDescs ).forEach( 
            (itemKey) => {
                 
            if(this.state.allTensesDescs[itemKey].tense_id == $wordTense){
                $wordTense = this.state.allTensesDescs[itemKey].description;
                return true;
            }
        
        })
    }
    if($wordTense == "0"){$wordTense = "";}
   if(this.state.wordRecord.ishaara_picture){
    switch (this.state.wordRecord.ishaara_picture.substr(0, 1)) {
        case 1:
            $Who = ("First Person");
            break;
        case 2:
            $Who = ("Second Person");
            break;
        case 3:
            $Who = ("Third Person");
            break;
    }
}
    if(this.state.wordRecord.pos3 == "PASS") {
        var $wordMaaloomMajhool = ("Passive Voice");
    } else if(this.state.wordRecord.pos3 == "REL") {
        var $wordMaaloomMajhool = ("Active Voice");
    } else {
        var $wordMaaloomMajhool = "";
    }
    
    if($wordNounType != "" && $wordNounType != "0"){
        Object.keys(this.state.allNounTpDescs ).forEach(
            (itemKey) => { 
            if(this.state.allNounTpDescs[itemKey].noun_type_id == $wordNounType){
                $wordNounType =this.state.allNounTpDescs[itemKey].description; 
                return true;
            }
        });
    }
    if($wordNounType == "0"){$wordNounType = "";}
    
    var $tsarfitashreeh = "";
    if($wordNounType != ""){$tsarfitashreeh += $wordNounType;}
    if($tsarfitashreeh != "" && $wordTense != ""){$tsarfitashreeh = this.insertCharAtEnd($tsarfitashreeh,'، ');}
    if($wordTense != ""){$tsarfitashreeh += $wordTense;}
    if($tsarfitashreeh != "" && $wordState != ""){$tsarfitashreeh =  this.insertCharAtEnd($tsarfitashreeh,'، ');}
    if($wordState != ""){$tsarfitashreeh += $wordState;}
    if($tsarfitashreeh != "" && $wordNumber != ""){$tsarfitashreeh =  this.insertCharAtEnd($tsarfitashreeh,'، ');}
    $tsarfitashreeh += $wordNumber;
    if($tsarfitashreeh != "" && $wordGender != ""){$tsarfitashreeh =  this.insertCharAtEnd($tsarfitashreeh,'، ');}
    $tsarfitashreeh += $wordGender;
    if($tsarfitashreeh != "" && $Who != ""){$tsarfitashreeh =  this.insertCharAtEnd($tsarfitashreeh,'، ');}
    $tsarfitashreeh += $Who;
    if($tsarfitashreeh != "" && $wordMaaloomMajhool != ""){$tsarfitashreeh =  this.insertCharAtEnd($tsarfitashreeh,'، ');}
    $tsarfitashreeh += $wordMaaloomMajhool;
    
    this.setState({tsarfitashreeh:$tsarfitashreeh}) ;
 }


    render(){
        var subElement = [];
        {Object.keys(this.state.moreRelatedRecords).forEach(
            (index) => {
                
                subElement.push(<OtherBaab transArray={this.state.transArray[index]} ayatBeforeWordArray={this.state.ayatBeforeWordArray[index]} ayatPageArray={this.state.ayatPageArray[index]} ayatAfterWordArray={this.state.ayatAfterWordArray[index]} ayatSurahArray={this.state.ayatSurahArray[index]} ayatNumberArray={this.state.ayatNumberArray[index]} allTraditionalBaabs={this.state.allTraditionalBaabs} moreRelatedRecord={this.state.moreRelatedRecords[index]} allBaabs={this.state.allBaabs}/>);
                })}
    return(
        <div className="grids-unit-bar pure-u-5-5 direction sarf_page_style">
                <div className="pure-g grids-unit-bar pure-u-5-5 sarf-custom-header">Sarf-e-Sagheer</div>
                
                <div className="pure-g grids-unit-bar pure-u-5-5 custom  ">
                   
                    <div className="pure-u-2-3 sarf_top p440-v1">
                        <div className="pure-u-1-3 tenseDisplay p440-v1" >{this.state.baab_code2 != 0?this.state.baab_code2:''} {this.state.ttbaab2} {this.getSpaceSeparatedChars('"'+this.state.rootText+'"')}</div>
                        <div className="pure-u-1-3 p440-v1 " >{ReactHtmlParser(this.state.wordRecord.text_aaraab0)} </div>
                        <div className="pure-u-1-3 tenseDisplay p440-v1" >{this.state.baab_code != 0?ReactHtmlParser(this.state.baab_code):''}{this.state.ttbaab}</div>
                        <div className="pure-u-5-5 tenseDisplay p440-v1" >{this.state.tsarfitashreeh}</div>
                    </div>
                    <div className="pure-u-1-5 sarf_top qc_urdufontsize" >{this.state.meaning}</div>
                       
                </div>
                <div className="pure-g grids-unit-bar pure-u-5-5  ">
                <table class="pure-table">
                    <thead>
                        <tr>
                            <th>ماضي</th>
                            <th>مضارع</th>
                            <th>امر</th>
                            <th>اسم فاعل</th>
                            <th>اسم مفعول</th>
                            <th>مصدر</th>
                            {(this.state.ism_e_mubaalgha != "")?<th class="sarfCell">اسم مبالغہ </th>:''}
                            {(this.state.seegha_mushabba != "")?<th class="sarfCell">صفت مشبہ</th>:''}
                                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{this.state.maazi}</td>
                            <td>{this.state.muzaaray}</td>
                            <td>{this.state.amar}</td>
                            <td>{this.state.faail}</td>
                            <td>{this.state.mafool}</td>
                            <td>{this.state.masdar}</td>
                            {(this.state.ism_e_mubaalgha != "")?<td class="sarfCell">{this.state.ism_e_mubaalgha}</td>:''}
                            {(this.state.seegha_mushabba != "")?<td class="sarfCell">{this.state.seegha_mushabba}</td>:''}
                        </tr>
                    </tbody>
                </table>
                </div>
                <div className="pure-g grids-unit-bar pure-u-5-5 sarf-custom-header"> Other Baabs </div>
                <div className="pure-g grids-unit-bar pure-u-5-5 ">
                   
                {subElement}
            
                   
                </div>
        </div>
    )
    }
}

export default Sarf;