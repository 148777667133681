import React, { Component } from 'react';
import PropTypes from 'prop-types';
import md5 from "md5";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ forceRefresh: true });
async function loginUser(credentials) {
 return fetch(global.config.API.URL+'/api/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            showSignUp:false,
            errorMessage:"",
            confirm_password:""
        };
      }
    
    onClick = () => {
        this.setState({ showSignUp: true });
    }
  handleSubmit = async e => {
    e.preventDefault();
    if(this.state.showSignUp && this.state.password != this.state.confirm_password){
        this.setState({ errorMessage: 'Password and confirm password must be same.'});
        return false;
    }
    const token = await loginUser({email:this.state.email,password:this.state.password,first_name:this.state.first_name,last_name:this.state.last_name});
    
    if(token.token!==false){
      var tokenObject = JSON.stringify(token);
       if(this.state.showSignUp){
        if(token.error !== false){
            //console.log(token.message);
            this.setState({ errorMessage: token.message });
           // this.state.errorMessage = token.message
        }else{
            this.setState({ showSignUp:false,first_name:'',last_name:'' });
        }
    }else{
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('isLogin',true);
        this.setState({ errorMessage: ''});
        history.push("/");
    }
    }else{
      this.setState({ errorMessage: 'Invalid email/password.'});
      localStorage.clear();
    }
    
  }

  
render(){
  return(
    <div>
      <fieldset>
         
      <form onSubmit={this.handleSubmit} className="pure-form pure-form-stacked">
      <div style={{color:'red'}} className="pure-input-1">{this.state.errorMessage}</div>
        {this.state.showSignUp && <div>
          <label for="stacked-email">First Name</label>
          <input type="text"  className="pure-input-1" required  onChange={e => this.setState({first_name:e.target.value})} />

          <label for="stacked-email">Last Name</label>
          <input type="text"  className="pure-input-1" required  onChange={e => this.setState({last_name:e.target.value})} />
            
        </div>} 
          <label for="stacked-email">Email</label>
          <input type="email"  className="pure-input-1" required  onChange={e => this.setState({email:e.target.value})} />
       
        <label for="stacked-email">Password</label>
          <input type="password"  className="pure-input-1" required onChange={e => this.setState({password:md5(e.target.value)})} />

          {this.state.showSignUp && <div> 
              <label for="stacked-email">Confirm Password</label>
          <input type="password"  className="pure-input-1" required onChange={e => this.setState({confirm_password:md5(e.target.value)})} />
          </div>}
        <div className="pure-u-1 pure-u-md-1-2">
          <button type="submit"  className="pure-button pure-button-primary pure-input-2">{this.state.showSignUp?'Submit':'Login'}</button>
        </div>
        
        {!this.state.showSignUp &&
        <div className="pure-u-1 pure-u-md-1-2">
          <button type="button"  className="pure-button pure-button-info pure-input-2 pull-right" onClick={this.onClick}>Sign Up</button>
        </div>
        }
      </form>
      </fieldset>
      </div>
  )
}
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
export default Login;