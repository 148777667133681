import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { CustomDialog, useDialog } from "react-st-modal";
import Login from "../pages/Login";
const Navbar = () => {
  const [open, setOpen] = useState("");
  function logout() {
    localStorage.clear();
    window.location.href = "/";
  }
  function openDrowDown() {
    if (open) {
      setOpen("");
    } else {
      setOpen("open");
    }
  }
  return (
    <div>
      <div id="navbar" class="navbar">
        <button
          type="button"
          class="navbar-toggle navbar-btn collapsed"
          data-toggle="collapse"
          data-target="#sidebar"
        >
          <span class="fa fa-bars"></span>
        </button>
        <a class="navbar-brand" href="/mushaf">
          <small>
            <i class="fa fa-book"></i> Quran Course 7{" "}
          </small>
        </a>

        <ul class="nav flaty-nav pull-right">
          <li>
            <a href="javascript:ShowOptions();">
              <i class="fa fa-gears"></i>
              <span id="user_info">Options</span>
            </a>
          </li>
          <li class={`user-profile ${open}`}>
            <a
              data-toggle="dropdown"
              onClick={() => openDrowDown()}
              href="#"
              class="user-menu dropdown-toggle"
            >
              <span id="user_info">Guest </span>
              <i class="fa fa-caret-down"></i>
            </a>

            <ul
              class="dropdown-menu dropdown-navbar"
              id="user_menu"
              style={{ minWidth: "150px" }}
            >
              <li>
                <a href="/login">
                  <i class="fa fa-sign-in"></i>
                  Sign In{" "}
                </a>
              </li>

              <li>
                <a href="/home/signup">
                  <i class="fa fa-sign-out"></i>
                  Sign up{" "}
                </a>
              </li>
              <li>
                <a href="mailto:quran@alfalahmanzil.net">
                  <i class="fa fa-book"></i>
                  Contact Us{" "}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="menu-container">
        <nav>
          <ul class="mcd-menu">
            {/* <li>
              <form action="/mushaf/index/" method="POST" class="search-form">
                <span class="search-pan">
                  <button type="submit">
                    <i class="fa fa-search"></i>
                  </button>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search ..."
                    autocomplete="off"
                    required=""
                  />
                </span>
              </form>
              <a href="" class="dropdown-toggle">
                <i class="fa fa-search"></i>
                <span>Search</span>
              </a>
            </li> */}
            <li>
              <a href="#" class="aauzbillah dropdown-toggle">
                <i class="fa fa-umbrella"></i>
              </a>
            </li>
            {/* <li>
              <a
                href="#"
                class="dropdown-toggle"
                title="Whole page recitation"
                id="recitepage"
              >
                <i class="fa fa-volume-up"></i>
              </a>
            </li>
            <li class="audio_player_pause" style={{ display: "none;" }}>
              <a href="#" class="dropdown-toggle" id="pausepageplay">
                <i class="fa fa-pause" title="Pause"></i>
              </a>
            </li>
            <li class="audio_player_play" style={{ display: "none;" }}>
              <a href="#" class="dropdown-toggle" id="continuepageplay">
                <i class="fa fa-play" title="Play"></i>
              </a>
            </li> */}

            <li>
              <a href="javascript:feedbackForm();" class="dropdown-toggle">
                <i class="fa fa-envelope" title="Feedback"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      {/* <div data-overlay="" class="bg-primary-3 text-white o-hidden">
    <section class="pb-5 pt-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-9 text-center">

                    <div class="p-0 surah-name h1 m-0">ﮍ</div>
                </div>
            </div>
        </div>
        <div class="position-absolute h-75 top left" data-jarallax-element="100" >
            <div class="blob blob-4 bg-gradient top left"></div>
        </div>
    </section>
</div> */}
    </div>
  );
};

export default Navbar;
