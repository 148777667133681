import { React, Component } from "react";
import "../../config";
import ReactHtmlParser from "react-html-parser";
import { createBrowserHistory } from "history";

import * as ReactDOM from "react-dom";
const history = createBrowserHistory({ forceRefresh: true });

class CustomDialogContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: "",
    };
    console.log(this.props);
  }

  handleSettingEvent = (e) => {
    var className = e.target.className;
    var classArray = className.split("_");
    if (typeof classArray[0] != "undefined") {
      if (classArray[0] == "clickable") {
        var page = classArray[1];
        history.push({
          pathname: "/",
          state: { page: page },
        });
      }
    }
  };

  componentWillMount() {
    fetch(
      global.config.API.URL +
        "/api/more_ayat?baabcode=" +
        this.props.baabcode +
        "&root=" +
        this.props.root_text +
        "&ayat_num=" +
        this.props.ayat_num +
        "&surah_num=" +
        this.props.surah_num +
        "&wordBaab=" +
        this.props.wordBaab
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          html: res.html,
        });

        setTimeout(() => {
          ReactDOM.findDOMNode(this).addEventListener(
            "click",
            this.handleSettingEvent.bind(this)
          );
        }, 50);
      });
  }

  render() {
    return (
      <div className="grids-unit-bar pure-u-5-5 direction sarf_page_style direction">
        <div className="pure-g grids-unit-bar pure-u-5-5 ">
          {/* <div className="pure-g grids-unit-bar pure-u-5-5 arabic"> {this.props.wordBaab}:More Ayat for</div> */}
          <div className="pure-g grids-unit-bar pure-u-5-5 ">
            {ReactHtmlParser(this.state.html)}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomDialogContent;
