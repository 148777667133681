import { React, Component } from "react";
import "../../config";
import ReactHtmlParser from "react-html-parser";
import { CustomDialog, useDialog } from "react-st-modal";
import CustomDialogContent from "./related_baab";

class OtherBaab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ttbaab: "",
      ttbaab2: "",
      baab_code: "",
      baab_code2: "",
      text: "",
      wordBaab: "",
    };
  }

  componentDidUpdate() {}
  getTraditionalBaab(wordBaab, givenTraditionalBaabID, allTraditionalBaabs) {
    var traditionalBaab = "";
    if (givenTraditionalBaabID != "") {
      Object.keys(allTraditionalBaabs).forEach((itemKey) => {
        if (allTraditionalBaabs[itemKey].id == givenTraditionalBaabID) {
          traditionalBaab = allTraditionalBaabs[itemKey].name;
          // break;
        }
      });

      if (wordBaab != traditionalBaab && traditionalBaab != "") {
        traditionalBaab = "(" + traditionalBaab + ")";
      } else {
        traditionalBaab = "";
      }
    }
    return traditionalBaab;
  }

  componentWillMount() {
    if (this.props.moreRelatedRecord.verb) {
      this.setState({
        muzaaray: this.props.moreRelatedRecord.verb.muzaaray,
        amar: this.props.moreRelatedRecord.verb.amar,
        faail: this.props.moreRelatedRecord.verb.faail,
        mafool: this.props.moreRelatedRecord.verb.mafool,
        masdar: this.props.moreRelatedRecord.verb.masdar,
        ism_e_mubaalgha: this.props.moreRelatedRecord.verb.ism_e_mubaalgha,
        seegha_mushabba: this.props.moreRelatedRecord.verb.seegha_mushabba,
        maazi: this.props.moreRelatedRecord.verb.maazi,
        text: this.props.moreRelatedRecord.text_aaraab0,
      });
    }
  }

  render() {
    var baab_content = [];
    Object.keys(this.props.allBaabs).forEach((itemKey) => {
      if (
        this.props.allBaabs[itemKey].baab_code ==
        this.props.moreRelatedRecord.baab_code
      ) {
        // this.setState({baab_code:this.props.allBaabs[itemKey].name})
        var ttbaab = this.getTraditionalBaab(
          this.props.allBaabs[itemKey].name,
          this.props.allBaabs[itemKey].traditional_baab_id,
          this.props.allTraditionalBaabs
        );
        this.state.wordBaab = this.props.allBaabs[itemKey].name;
        console.log(this.props.allBaabs[itemKey].name + "==" + ttbaab);
        baab_content.push(
          <div>
            <span>{this.props.allBaabs[itemKey].name}</span>
            <span className="baabinheader">{ttbaab}</span>
          </div>
        );
      }

      if (
        this.props.allBaabs[itemKey].baab_code ==
        this.props.moreRelatedRecord.baab_code2
      ) {
        // this.setState({baab_code2:this.state.allBaabs[itemKey].name})
        var ttbaab2 = this.getTraditionalBaab(
          this.props.allBaabs[itemKey].name,
          this.props.allBaabs[itemKey].traditional_baab_id,
          this.props.allTraditionalBaabs
        );
        // this.setState({ttbaab2:ttbaab2})
      }
    });

    return (
      <div className="grids-unit-bar pure-u-5-5 direction sarf_page_style">
        <div className="pure-g grids-unit-bar pure-u-5-5 ">
          <div className="pure-g grids-unit-bar pure-u-4-5 baabinheader p440-v1">
            {" "}
            {baab_content}
          </div>

          <div className="pure-g grids-unit-bar pure-u-1-5 moreLink">
            <a
              href="#"
              onClick={async () => {
                setTimeout(() => {
                  CustomDialog(
                    <CustomDialogContent isPopUp="1"
                      wordBaab={this.state.wordBaab}
                      baabcode={this.props.moreRelatedRecord.baab_code}
                      root_text={this.props.moreRelatedRecord.root_text}
                      surah_num={this.props.moreRelatedRecord.surah_num}
                      ayat_num={this.props.moreRelatedRecord.ayat_num}
                    />,
                    {
                      title: "More ayat for " + this.state.wordBaab,
                      showCloseIcon: true,
                      className:'other_baab_modal_width'
                    },
                    
                  );
                }, 50);
              }}
            >
              ..More verses
            </a>
          </div>

          <div className="pure-g grids-unit-bar pure-u-5-5  ">
            <table class="pure-table">
              <thead>
                <tr>
                  <th class="sarfCell">ماضي</th>
                  <th class="sarfCell">مضارع</th>
                  <th class="sarfCell">امر</th>
                  <th class="sarfCell">اسم فاعل</th>
                  <th class="sarfCell">اسم مفعول</th>
                  <th class="sarfCell">مصدر </th>
                  <th class="sarfCell">اسم مبالغہ </th>
                  <th class="sarfCell">صیغہ مشبہ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{ReactHtmlParser(this.state.maazi)}</td>
                  <td>{ReactHtmlParser(this.state.muzaaray)}</td>
                  <td>{ReactHtmlParser(this.state.amar)}</td>
                  <td>{ReactHtmlParser(this.state.faail)}</td>
                  <td>{ReactHtmlParser(this.state.mafool)}</td>
                  <td>{ReactHtmlParser(this.state.masdar)}</td>
                  <td class="sarfCell">
                    {ReactHtmlParser(this.state.ism_e_mubaalgha)}
                  </td>
                  <td class="sarfCell">
                    {ReactHtmlParser(this.state.seegha_mushabba)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pure-g grids-unit-bar pure-u-5-5 ">
            <div className="qc_font">
              <div
                onclick="OpenPage('<?php echo $ayatPageArray; ?>')"
                className="more_ayat clickable qc_font qc_fontsize"
              >
                <span>{this.props.ayatBeforeWordArray}</span>
                <span className="highlighted_word_in_example_ayat">
                  {this.state.text}
                </span>
                <span>{this.props.ayatAfterWordArray}</span>
                <span dir="ltr" className="ayatReference">
                  {this.props.ayatSurahArray}
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="qc_urdufontsize">
                <span>{this.props.transArray}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OtherBaab;
