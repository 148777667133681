import  React  from "react";

const About = () =>{
    return(
        <div className="pure-g">
                <div className="py-4">
                    <h1>About</h1>
                </div>
        </div>
    )
}

export default About;