import { React, Component } from "react";
import "../../config";
import ReactHtmlParser from "react-html-parser";
import { createBrowserHistory } from "history";

import * as ReactDOM from "react-dom";
const history = createBrowserHistory({ forceRefresh: true });

class AyatTranslation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      html: "",
    };
   console.log(this.props)
  }

 

  componentWillMount() {
    fetch(
      global.config.API.URL +
        "/api/ayat_tran?ayat_num=" +
        this.props.ayat_num +
        "&lang_id="+this.props.lang_id +
        "&surah_num="+this.props.surah_num
    )
      .then((res) =>res.json())
      .then((res) =>{
          console.log(res)
        this.setState({
            html:res.translation ,
          });
        })
       
  }

  render() {
    return (
      <div className="grids-unit-bar pure-u-5-5 direction">
        <div className="pure-g grids-unit-bar pure-u-5-5 ">
          {/* <div className="pure-g grids-unit-bar pure-u-5-5 arabic"> {this.props.wordBaab}:More Ayat for</div> */}
          <div className="pure-g grids-unit-bar pure-u-5-5  ayat_translation_font">
              
            {ReactHtmlParser(this.state.html)}
          </div>
        </div>
      </div>
    );
  }
}

export default AyatTranslation;
