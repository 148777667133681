import React from 'react';
import '../node_modules/purecss/build/pure.css';
import '../node_modules/purecss/build/pure-min.css';
import '../node_modules/purecss/build/grids-min.css';
import '../node_modules/purecss/build/grids-responsive-min.css';
import './App.css';
import './style.css';
import Home  from "./components/pages/Home";
import About from "./components/pages/About";
import Sarf from "./components/pages/Sarf";
import Navbar from "./components/layout/Navbar";
import UserTable from "./components/pages/UserTable";
import Home2 from "./components/pages/Home2";
import Home3 from "./components/pages/Home3";
import { BrowserRouter as Router,Switch,useHistory  } from "react-router-dom";

function App() {
  
  return (
    <Router history={useHistory}>
    <div className="App ">
      
        <Navbar /> 
        <Switch history={useHistory}>
          <Home exact path="/" component={Home}/>
          <Home2 exact path="/Home" component={Home2}/>
          <Home3 exact path="/Home2" component={Home3}/>
          <About exact path="/About"  component={About}/>
          <Sarf exact path="/Sarf/:id"  component={Sarf}/>
          <UserTable exact path="/users"  component={UserTable}/>
        </Switch>
        
    </div>
     </Router>
  );
}

export default App;
