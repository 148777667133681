import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import "../../config";


function UserTable() {

  const [users, setUsers] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
 // const countPerPage = 10;
 const ChangeStatus = (id) =>{
  axios.post(global.config.API.URL +`/api/changeuser_status`,{
    id: id,
  }).then(res => {
    getUserList(perPage);
  }).catch(err => {
   
  });
}
  const getUserList = (perPage) => {
    axios.get(global.config.API.URL +`/api/users?page=${page}&per_page=${perPage}&delay=1`).then(res => {
      setUsers(res.data);
    }).catch(err => {
      setUsers({});
    });
  }
  const columns = [
    {
      name: 'ID',
      selector: 'id',
      sortable: true,
    },
    {
      name: 'Name',
      sortable: true,
      selector: 'display_name'
    },
    {
      name: 'Email',
      sortable: true,
      selector: 'email'
    },
    {
      name:'Role',
      sortable: true,
      selector:'user_type_id',
      cell: row => (row.user_type_id == 4)?'Student':(row.user_type_id == 3)?'Teacher':(row.user_type_id == 2)?'Admin':'Super Admin'
    },
    {
      name:'Status',
      sortable: true,
      selector:'approved',
      cell: row => (row.approved == 1)?'Approved':'Not Approved'
    },
    {
      name:'Approved/UnApproved',
      sortable: true,
      selector:'user_type_id',
      cell: row =><input type="button"  onClick={() => ChangeStatus(row.id)} value={(row.approved == 1)?'Un-Approved':'Approved'}/> 
    },
    
  ];
  useEffect(() => {
    getUserList(10);
  }, [page]);
  const handlePerRowsChange = async(newPerPage, page) => {
    setPage(page)
    getUserList(newPerPage);
    setPerPage(newPerPage);
  };
  return (
    <div className="App">
     
      <DataTable
        title="User List"
        columns={columns}
        striped
        fixedHeader
        responsive
        fixedHeader
        data={users.data}
        highlightOnHover
        pagination
        paginationServer
        paginationTotalRows={users.totalRecords}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationPerPage={perPage}
        selectableRows
        paginationComponentOptions={{
          noRowsPerPage: false
        }}
        onChangePage={page => setPage(page)}
      />
    </div>
  );
}

export default UserTable;