import { React, Component } from "react";
import "../../config";
import ReactHtmlParser from 'react-html-parser';
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select'
import { Link, NavLink } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import { reducer as jPlayers } from 'react-jplayer';
import { Redirect } from 'react-router-dom'
// Styles the jPlayer to look nice
import * as ReactDOM from 'react-dom'
import { CustomDialog } from "react-st-modal";
import AudioPlayer from './Audio';
import AyatTranslation from './Translation';
import DragM from "dragm";
import { Modal, Button } from "antd";
import "antd/dist/antd.css";
class BuildTitle extends Component {
  updateTransform = transformStr => {
    this.modalDom.style.transform = transformStr;
  };
  componentDidMount() {
    this.modalDom = document.getElementsByClassName(
      "ant-modal-wrap"
    )[0];
  }
  render() {
    const { title } = this.props;

    return (
      <DragM updateTransform={this.updateTransform}>
        <div>translation</div>
      </DragM>
    );
  }
}
class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { visible: false, apiResponse: "", page: 2, OnlyAyatHighlight: false, ishaaraatResult: "", isOpen: false, sarf_id: "", ayatArray: "", redirect: false, timeResult: "", surahOptions: "", paraOptions: [], currentsurah: {}, para: {}, isDisplayColorcode: true, surah: 1, currentpara: 1 };
    this.page = 2;
    this.time = 1;

    this.surah_num = 1_1;
    this.state.fontfamily = 'p440-v2';//Majidi default font family
    this.state.currentfontfamily = { key: 'p440-v2', label: 'Al Mushaf' };//Majidi default font family
    //this.state.isDisplayColorcode = true;//Majidi default font family
    this.fontsize = 42;
    //this.para = 1
    this.fontOptions = [{ value: 'p440-v1', label: 'Majidi' }, { value: 'p440-v2', label: 'Al Mushaf' },{value:'p440-v3',label:'Hafs'}]//{value:'p440-v3',label:'Hafs'}
    this.store = createStore(combineReducers({ jPlayers }));
    //this.child = React.createRef();

    //this.state.currentCompObj = this;
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  openModal() {
    this.setState({ visible: true });
  }
  closeModal() {
    this.setState({ visible: false });
  }

  clickEvent = (e) => {
    var page = e.target.attributes.value.nodeValue;
    // eslint-disable-next-line
    if (Number(page) == 1 && this.page < 611) {
      this.page = parseInt(this.page) + 1;
      this.setState({ isDisplayColorcode: this.state.isDisplayColorcode, page: this.page })
      this.callAPI()
      // eslint-disable-next-line
    } else if (Number(page) == -1 && this.page > 2) {
      this.page = parseInt(this.page) - 1;
      this.setState({ isDisplayColorcode: this.state.isDisplayColorcode, page: this.page })
      this.callAPI();
      //this.componentDidMount();
    }

  }
  handleChange = (e) => {
    //if(e.key == 'Enter'){
    this.setState({ page: e.target.value })
    this.page = e.target.value;
    this.callAPI()
    // }
  }

  handleSurahChange = (e) => {
    this.page = 0;
    var surah = e.value;
    var surahArray = surah.split("_");
    this.surah_num = surah;
    this.page = surahArray[1];
    this.setState({ page: surahArray[1] })

    this.callAPI();
  }
  hideSettingPannel = (e) => {
    //  console.log(e.target)
    // if(this.state.isOpen !== false){
    //   var configNode = document.getElementById('config-tool');
    //   configNode.className = '';
    //   this.state.isOpen = false;
    // }
  }
  settingClick = (e) => {
    if (this.state.isOpen === false) {
      var isOpen = false;
    } else {
      var isOpen = true;
    }

    var configNode = document.getElementById('config-tool');
    var configNodeButton = document.getElementById('config-tool-cog');
    ReactDOM.findDOMNode(this).addEventListener('config-tool-cog', this._handleSettingEvent);

    //configNodeButton.addEventListener('click', function() {
    // console.log(isOpen);
    if (isOpen !== false) {
      configNode.className = '';
    } else {
      configNode.className = 'open';
    }
    if (isOpen === false) {
      isOpen = true;
    } else {
      isOpen = false;
    }
    this.setState({ isOpen: isOpen });
    //});


  }

  handleParahChange = (e) => {
    this.setState({ para: e.value })
    var para = e.value;
    console.log(para);
    var paraArray = para.split("_");
    this.page = paraArray[1];
    this.setState({ page: paraArray[1] })
    this.callAPI()

  }

  redirectToSarf(id, isSarf) {
    // console.log(id);
    if (isSarf) {
      this.setState({
        redirect: true, sarf_id: id
      })
    }
  }
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to={'/Sarf/' + this.state.sarf_id} />

    }
  }

  handleResize = (e) => {

    var pageContainer = document.getElementById('reading_container');
    if(pageContainer){
    var container_width = pageContainer.offsetWidth;


    var node = document.getElementsByClassName('ayat_container');
    var fontSize = (container_width / 894 * 72)/2+'px';
   // node.style.back = fontSize+'px';

   // if (node.length > 0) {
      for (var i = 0; i <= node.length; i++) {
        if (node[i]) {
          console.log(node[i])
         // if (typeof node[i] != 'undefined') {
            node[i].style.fontSize = fontSize+'px !important';
        //  }
        }
      }
    }
    //}
   // node.style.fontSize = fontSize+'px';
  }
  fontSizeHandler = (e) => {
    var classString = e.target.attributes.class.nodeValue;
    // eslint-disable-next-line
    if (classString.indexOf("minus") != -1) {
      this.fontsize = this.fontsize - 1;
    } else {
      this.fontsize = this.fontsize + 1;

    }
    this.callAPI()
  }

  callAPI() {
    if(this.page <= 611 && this.page > 1){
    if (this.props.location.state) {
      this.page = this.props.location.state.page;
    }
    fetch(global.config.API.URL + "/api/dashboard?page=" + this.page)
      .then(res => res.json())
      .then(res => {

        this.setState({ apiResponse: res.data, currentpara: res.para, currentsurah: res.surah });
        this.startPlaying('ayat_999999');//clear highlighting
        this.updateStates();
      })

    fetch(global.config.API.URL + "/api/qiraat?page=" + this.page)
      .then(res => res.json())
      .then(res => {
        this.setState({ timeResult: res })
        this.refs.child.setMedia(res);

      });
    fetch(global.config.API.URL + "/api/ishaaraat?page=" + this.page)
      .then(result => result.json())
      .then(result => {
        this.setState({ ishaaraatResult: result })
      })

    }
  }
  updateStates() {
    var currentParaSelected = false;
    for (var i = 0; i < this.state.paraOptions.length; i++) {
      var para = this.state.paraOptions[i];
      if (para) {
        var paraArray = para.value.split("_");
        // eslint-disable-next-line
        if (this.state.currentpara == paraArray[0] && currentParaSelected === false) {
          currentParaSelected = true;
          // eslint-disable-next-line
          this.state.para = para;
        }
      }
    }
    setTimeout(this.setState({ para: this.state.para }), 100);
  }
  callSurahAPI() {

    fetch(global.config.API.URL + "/api/surah?page=" + this.page)
      .then(res => res.json())
      .then(res => this.setState({ surahOptions: res }));
    fetch(global.config.API.URL + "/api/getparas")
      .then(res => res.json())
      .then(res => this.setState({ paraOptions: res }));


  }

  decodeHtml(text) {
    return text
      .replace(/&amp;/g, '&')
      .replace(/&lt;/, '<')
      .replace(/&gt;/, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'");
  }
  getLine(words) {
    var container = [];

    Object.keys(words).forEach(
      (word) => {
        var html = '';
        var nonmushafrow = '';
        var lineObj = words[word];
        var ayat_num = 0;
        var next_ayat_started = false;

        Object.keys(lineObj).forEach((line) => {
          var punctuation = '';
          var ayatClass = '';
          var sarf_sagheer_word = '';
          if (ayat_num != lineObj[line].ayat_num) {
            if (next_ayat_started !== false) {
              html += '</div>';

            }
            html += '<div class=" ayat_container  ayaaat_' + (lineObj[line].ayat_num) + '_'+(lineObj[line].surah_num)+'">';
            next_ayat_started = true;

          }
          ayat_num = lineObj[line].ayat_num;
          if (lineObj[line].verbs_id > 0) {
            sarf_sagheer_word = 'sarf_sagheer';
          }
          // eslint-disable-next-line
          nonmushafrow = ((lineObj[line].Words_Corpus_SNO == null && lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num == null) || (this.page < 4 && word <= 2)) ? 'nonmushafrow ' : ''
          ayatClass = (lineObj[line].Words_Corpus_SNO == null && lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num > 0) ? 'smallfont' : '';
          // eslint-disable-next-line
          var isAyatEndSymbol = (lineObj[line].mushaf_word_id == null && lineObj[line].surah_header == 0 && (lineObj[line].text_aaraab).match("/[0-9]+/"));

          // eslint-disable-next-line
          var text = (lineObj[line].colored_qsoft && this.state.isDisplayColorcode) ? '<span id="' + lineObj[line].display_word_id + '" onClick="redirectToSarf(' + lineObj[line].display_word_id + ')" class="wordsdisplay ' + sarf_sagheer_word + '">' + this.decodeHtml(lineObj[line].colored_qsoft) + '</span>' : (lineObj[line].mushaf_word_id != null && lineObj[line].surah_header >= 0) ? '<span id="' + lineObj[line].display_word_id + '" onClick="redirectToSarf(' + lineObj[line].display_word_id + ')" class=" wordsdisplay ' + sarf_sagheer_word + '">' + lineObj[line].text_aaraab0 + '</span>' : (lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num != null && lineObj[line].surah_header == 1 && !isAyatEndSymbol) ? '<span id="' + lineObj[line].display_word_id + '" onClick={redirectToSarf} class="' + ayatClass + ' wordsdisplay ' + sarf_sagheer_word + '">' + lineObj[line].text_aaraab0 + '</span>' : '<span class="' + ayatClass + ' wordsdisplay ' + sarf_sagheer_word + '" onClick={redirectToSarf}>' + lineObj[line].text_aaraab + '</span>';
          punctuation = (lineObj[line].punctuation !== null) ? "<span id='" + lineObj[line].display_word_id + "' onClick='redirectToSarf(" + lineObj[line].display_word_id + ")' class=' punctuationwordOnMushaf smallfont'>" + lineObj[line].punctuation.trim() + '</span>' : '';
          if (sarf_sagheer_word) {
            //text = '<a href="/Sarf?' + lineObj[line].display_word_id + '">' + text + '</a>';
          }
          html += text + punctuation;


        });
        html += '</div>';
        //html+= '<span style="display:inline-block"></span>';
        //container.push(<div id={`line_` + word} className={`flex-container ${nonmushafrow}`}>{ReactHtmlParser(html)}</div>)
        container.push(<span id={`line_` + word} className={`flex-container ${nonmushafrow}`}>{ReactHtmlParser(html)}</span>)

      })

    return container;


  }

  getAyatTranslation = (ayat_num, lang_id, surah_num) => {
    fetch(
      global.config.API.URL +
      "/api/ayat_tran?ayat_num=" +
      ayat_num +
      "&lang_id=" + lang_id +
      "&surah_num=" + surah_num
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          translation: res.translation
        });
        this.openModal();
      })
  }
  
  startPlaying = (ayat_class) => {
    const node = ReactDOM.findDOMNode(this);
    var node2 = document.getElementsByClassName('ayat_container');
    if (node2.length > 0) {
      for (var i = 0; i <= node2.length; i++) {
        if (node2[i]) {
          node2[i].style.backgroundColor = '';
        }
      }
    }
    var node2 = document.getElementsByClassName(ayat_class);

    if (node2.length > 0) {
      for (var i = 0; i <= node2.length; i++) {
        if (node2[i]) {
          node2[i].style.backgroundColor = 'khaki';

        }
      }


    }


  }
  ayatRecitation(ayat_num, surah_num) {
    //this.setState({OnlyAyatHighlight:true});
    this.startPlaying('ayat_' + ayat_num+'_'+surah_num)
    this.getAyatTranslation(ayat_num, 2, surah_num)//first param is ayat num and second is lang id
    this.refs.child.playAyat(ayat_num);
  }
  componentWillMount() {
    //ReactDOM.findDOMNode(this).find('sarf_sagheer').on('clcik',this.redirectToSarf)

    //console.log(element);
    this.callSurahAPI();
    this.callAPI();

  }
  componentDidUpdate() {



    // eslint-disable-next-line
    for (var i = 0; i < this.state.surahOptions.length; i++) {
      var surah = this.state.surahOptions[i];
      var surahArray = surah.value.split("_");
      // eslint-disable-next-line
      if (this.state.currentsurah == surahArray[0]) {
        //eslint-disable-next-line
        this.state.surah = surah
      }
    }

  }


  render() {
    const title = <BuildTitle title="Basic Modal" />;
    var container = [];
    var ishaaraat_container = [];
    var words = this.state.apiResponse;
    var ishaaraat = this.state.ishaaraatResult;
    var line_number = 1;
    Object.keys(ishaaraat).forEach(
      (index) => {
        if (ishaaraat[index].line_num != null && ishaaraat[index].line_num != 0) {
          var top = (ishaaraat[index].line_num != null) ? (ishaaraat[index].line_num * 90) + 'px' : (line_number * 90) + 'px';
          var e2 = document.getElementById('line_' + ishaaraat[index].line_num);
          if (e2) {
            top = e2.offsetTop - 200 + "px";
          }



          line_number++;
          ishaaraat_container.push(<div style={{ top: top }} id={ishaaraat[index].id} className={`ishaara`}>{ishaaraat[index].description}</div>)
        }
      })
    Object.keys(this.state.apiResponse).forEach(
      (word) => {
        var html = '';
        var nonmushafrow = '';
        var lineObj = words[word];
        var ayat_num = '';
        var next_ayat_started = false;
        var bismillah = false;

        var List = [];
        Object.keys(lineObj).forEach((line) => {
          var punctuation = '';
          var ayatClass = '';
          var sarf_sagheer_word = '';

          if (lineObj[line].verbs_id > 0) {
            sarf_sagheer_word = 'sarf_sagheer';
          }
          if(lineObj[line].mushaf_word_id == null && lineObj[line].Words_Corpus_SNO == null && (lineObj[line].ayat_num == 0 || lineObj[line].ayat_num == null)){
            bismillah = true;
          }
          // eslint-disable-next-line
          nonmushafrow = ((lineObj[line].Words_Corpus_SNO == null && lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num == null) || (this.page < 4 && word <= 2)) ? 'nonmushafrow' : ''
          ayatClass = (lineObj[line].Words_Corpus_SNO == null && lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num > 0) ? 'smallfont' : '';
          // eslint-disable-next-line
          var isAyatEndSymbol = (lineObj[line].mushaf_word_id == null && lineObj[line].surah_header == 0 && (lineObj[line].text_aaraab).match("/[0-9]+/"));

          // eslint-disable-next-line
          var text = (lineObj[line].colored_qsoft && this.state.isDisplayColorcode) ? this.decodeHtml(lineObj[line].colored_qsoft) : (lineObj[line].mushaf_word_id != null && lineObj[line].surah_header >= 0) ? lineObj[line].text_aaraab0 : (lineObj[line].mushaf_word_id == null && lineObj[line].ayat_num != null && lineObj[line].surah_header == 1 && !isAyatEndSymbol) ? lineObj[line].text_aaraab0 : lineObj[line].text_aaraab;
          punctuation = (lineObj[line].punctuation !== null) ? lineObj[line].punctuation : '';

          html = text;

          var ayat_num = lineObj[line].ayat_num;
           if(next_ayat_started !== false){
           //  List.push(ReactHtmlParser('</span>'));

           }

          if (ayat_num != lineObj[line].ayat_num) {

           // List.push(ReactHtmlParser('<span class="   ayaaat_'+(lineObj[line].ayat_num)+'">'));
            next_ayat_started = true;

          }
           ayat_num = lineObj[line].ayat_num;
          if (ayatClass) {
            // List.push(ReactHtmlParser(html))

            List.push(<span class={`ayat_container wordsdisplay ${sarf_sagheer_word} ${ayatClass} ayat_` + (lineObj[line].ayat_num)+`_`+lineObj[line].surah_num} onClick={() => this.ayatRecitation(lineObj[line].ayat_num, lineObj[line].surah_num)}>{ReactHtmlParser(html)}</span>)

          } else {
            //List.push(ReactHtmlParser(html))+ '_'+(lineObj[line].surah_num)+'
            List.push(<span className={`ayat_container wordsdisplay  ayat_` + (lineObj[line].ayat_num)+`_`+lineObj[line].surah_num} id={lineObj[line].display_word_id} onClick={() => this.redirectToSarf(lineObj[line].display_word_id, sarf_sagheer_word)} id={lineObj[line].display_word_id}>{ReactHtmlParser(html)}</span>)

          }
          if (punctuation) {
            List.push(<span className={`ayat_container punctuation  ayat_` + (lineObj[line].ayat_num)+`_`+lineObj[line].surah_num} id={lineObj[line].display_word_id} >{ReactHtmlParser(punctuation)}</span>)

          }
          
        })
        //List.push(ReactHtmlParser('</span>'));
        if(bismillah === false ){
          container.push(List)
        }else{
          container.push(<div id={`line_${word}`} className={`flex-container ${nonmushafrow}  `}>{List}</div>)
        }
        //container.push(<div id={`line_${word}`} className={`flex-container ${nonmushafrow}  `}>{List}</div>)
       
        // {this.getLine(this.state.apiResponse)}

      })
    document.addEventListener('click', this.hideSettingPannel);
    window.addEventListener('resize', this.handleResize)
    return (

      <div className=" grids-unit-bar pure-u-5-5 ">
        {this.renderRedirect()}
        <div id="config-tool" class="closed">
          <Link id="config-tool-cog" onClick={this.settingClick}>
            <FontAwesomeIcon icon={faCog} />
          </Link>

          <div id="config-tool-options" className="pure-g">
            <div className=" pure-u-5-5  ">
              <h3>Page Setting</h3>
            </div>

            <div className=" pure-u-5-5  ">


              <BootstrapSwitchButton
                checked={this.state.isDisplayColorcode}
                onlabel='Remove Colors'
                onstyle='success'
                offlabel='Apply Colors'
                size='sm'
                offstyle='light'
                // eslint-disable-next-line
                style=' lg  margin-5'
                onChange={(checked: boolean) => {
                  if (checked) {
                    this.setState({ isDisplayColorcode: true })
                  } else {
                    this.setState({ isDisplayColorcode: false })
                  }

                }}
              />

            </div>
            <div className=" pure-u-5-5  ">
              <Select className='margin-5 ' options={this.fontOptions} placeholder="Select Font" value={this.state.currentfontfamily} onChange={(e) => {
                // eslint-disable-next-line
                if (e.value == 'p440-v1') {
                  this.setState({ fontfamily: e.value })
                  this.setState({ currentfontfamily: { key: e.value, label: 'Majidi' } })
                }else if(e.value == 'p440-v3'){
                  this.setState({ fontfamily: 'p440-v3' })
                  this.setState({ currentfontfamily: { key: 'p440-v3', label: 'hafs' } })
                
                } else {
                  this.setState({ fontfamily: 'p440-v2' })
                  this.setState({ currentfontfamily: { key: 'p440-v2', label: 'Al Mushaf' } })
                }

              }} />
            </div>
          </div>
        </div>

        <div className="pure-g grids-unit-bar pure-u-5-5 custom">
          <div className=" pure-u-1-1 pure-u-md-1-3 ">
            <Select className='margin-5 text-direction' options={this.state.paraOptions} onChange={this.handleParahChange} value={this.state.para} />
          </div>
          <div className="pure-g">
            <div className=" pure-u-1-3 pure-u-md-1-3" >

              <NavLink to="#" className="next " value="1" onClick={this.clickEvent}>&#8249;</NavLink>
            </div>
            <div className=" pure-u-1-3 pure-u-md-1-3">
              <input className="input-class " id="pagination_field" type="text" value={this.page} onChange={this.handleChange} />
            </div>
            <div className=" pure-u-1-3 pure-u-md-1-3">
              <NavLink to="#" value="-1" onClick={this.clickEvent} className="next ">&#8250;</NavLink>
            </div>
          </div>
          <div className="pure-u-1-1 pure-u-md-1-3 ">

            <Select className='margin-5 text-direction' id="surah_list" options={this.state.surahOptions} onChange={this.handleSurahChange} value={this.state.surah} />

          </div>

        </div>
        <div className={` direction shadow page-container d-flex flex-wrap justify-content-center `}>
          {/* <div className="pure-u-1-4 pointers">Pointer Area</div> */}
          {/* <div className={`pure-u-17-24 ${this.state.fontfamily}`}>{this.getLine(this.state.apiResponse)}</div>  */}
          <div className=" pure-u-5-5 ">

            {/* <div className=" pure-u-1-5 ">
                    <div className="ishaaraa-container ishaarat_display ">{ishaaraat_container}</div>
                  </div>  */}

            <div id="reading_container" className=" md-page col-xl-8 ">
              {/* <div className={`reading-page `} >{this.getLine(this.state.apiResponse)}</div>  */}
              <article className="article">
                {/* <div className={`reading-page quran-page  shadow-lg ${this.state.fontfamily}`} >{this.getLine(this.state.apiResponse)}</div> */}
                <div className={`reading-page quran-page2  shadow-lg ${this.state.fontfamily}`} >{container}</div>
              </article>

            </div>


          </div>


          {/* <div className={` w-100 `} ref="myDiv">
                  {container}
                    </div>  */}
        </div>

        <Provider store={this.store}>
          <AudioPlayer ref="child" store={this.store} timeResult={this.state.timeResult} ayatArray={[]} startPlaying={this.startPlaying} clearHighlight={this.clearHighlight}/>
        </Provider>

        <Modal
          title={title}
          visible={this.state.visible}
          onOk={this.closeModal}
          footer={null}
          className = {`translation_container`}
          onCancel={this.closeModal}
          closable = {false}
        >
          {this.state.translation}

        </Modal>


      </div>

    );
  }
}

export default Home;