import { React, Component } from "react";
import JPlayer, {
  initializeOptions,
  Gui,
  Audio,
  actions,
  Mute,
  Play,
  VolumeBar,
  BrowserUnsupported,
} from "react-jplayer";
import "../../config";

//import { Provider } from 'react-redux';
//import { createStore, combineReducers } from 'redux';
//import { reducer as jPlayers } from 'react-jplayer';
//import { pause } from 'react-jplayer/lib/actions/actions';

class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.timeupdate = 0;
    this.timeResult = this.props.timeResult;
    this.firstloadedpage = 2;
    this.state = {
      totalPlayed: 0,
      playAyatOnly: false,
      timeResult: this.props.timeResult,
      pause: false,
      timeout: null,
      pauseOn: 0,
      PlayingCounter: null,
      playing_ayat: null,
      startTime: 0,
      endTime: 0,
      mediaFileArray:[],
      initialStartTime:0,
      current_surah:0,
      totalSurah:0,
      lastSurah:0,
      first_surah:0
    };

    this.PlayingCounter = null;
    this.seconds = 0;
    this.totalPlayed = 0;
    this.ayatArray = [];
    //const store = createStore(combineReducers({ jPlayers }));
    // this.props.actions.setOption('AudioPlayer','play',44);
    // this.props.dispatch(actions.play('AudioPlayer','play',44))
    this.time = 0;
    this.defaultOptions = {
      id: "AudioPlayer",
      keyEnabled: true,
      verticalVolume: true,
      media: {
        sources: {
          mp3: "",
        },
        free: true,
      },
    };
  }

  PlayHandleChange = (e) => {
    this.state.playAyatOnly = false;
    clearInterval(this.state.PlayingCounter);
    if (this.state.playing_ayat === null) {
      this.setState({ playing_ayat: this.props.timeResult[0].ayat_num });
    }

    /// this.setState({totalPlayed: this.totalPlayed });
    //this.playing_ayat = this.props.timeResult[0].ayat_num;
    /// this.setState({playing_ayat:this.props.timeResult[0].ayat_num});
    setTimeout(() => this.play(), 20);

    if (this.state.previously_played !== false) {
      this.setState({ pauseOn: this.state.totalPlayed });
      // this.setState({totalPlayed:this.totalPlayed});
    }
  };
  convert(input) {
    var minutes = input;
    var seconds = 0;
    return (minutes * 60 + seconds).toFixed(3);
  }

  play() {
    this.ayatArray = [];
    if (this.props.timeResult.length > 0) {
      var startTime = this.props.timeResult[0].start_time;

      // this.totalPlayed = startTime;

      //console.log(this.state.startTime);

      //console.log(this.state.previously_played);
      //var endTime = this.props.timeResult[this.props.timeResult.length - 1]
      //  .end_time;

      if (this.state.totalPlayed > 0) {
        this.totalPlayed = this.state.totalPlayed;
      } else {
        this.totalPlayed = this.state.startTime;
      }

      if (this.state.pause === false) {
       // var endTime = this.state.endTime - this.state.totalPlayed;
        console.log('play function end time '+this.state.endTime);
        this.props.store.dispatch(
          actions.play("AudioPlayer", this.state.startTime)
        );
        this.setState({ pause: true });
       // this.setState({ endTime: endTime });

      
      } else {
        this.setState({ pause: false });
        clearInterval(this.PlayingCounter);
        clearTimeout(this.timeout);
      }
    }

    this.timeout = setTimeout(() => {
      this.pause();
      this.clearAllStates();
    }, Math.ceil(((this.state.endTime - this.state.startTime) + 1) * 1000));
    this.startPlayingCounter();
  }
  clearAllStates() {
    clearInterval(this.PlayingCounter);
    clearInterval(this.state.AyatInterval);
    clearTimeout(this.timeout);
    this.setState({ pauseOn: 0 });
    this.totalPlayed = 0;
    this.setState({ pause: false });
    this.setState({ totalPlayed: 0 });
    this.setState({ startTime: this.props.timeResult[0].start_time });
    this.setState({ previously_played: false });
    clearInterval(this.state.PlayingCounter);
    this.setMedia(this.props.timeResult);
  }
  Stop() {
    this.props.store.dispatch(actions.pause("AudioPlayer"));
    this.state.playAyatOnly = false;
    this.setState({ pauseOn: this.totalPlayed });
    this.setState({ totalPlayed: this.totalPlayed });
    this.setState({ pause: true });
    this.state.previously_played = true;
    if (this.timeout) {
      //clearTimeout(this.timeout);
    }
  }
  pause() {
    this.props.store.dispatch(actions.pause("AudioPlayer"));
    this.state.playAyatOnly = false;
    this.setState({ pauseOn: this.totalPlayed });
    //this.setState({totalPlayed:this.totalPlayed});
    this.setState({ pause: false });
    this.state.previously_played = true;
  }

  startPlayingCounter() {
    clearInterval(this.PlayingCounter);
    //this.totalPlayed = this.state.totalPlayed;
    this.PlayingCounter = setInterval(() => {
      this.incrementSeconds();
    }, 1000);
  }
  setMedia(mediaArray) {
    clearInterval(this.PlayingCounter);
     clearInterval(this.state.AyatInterval);
     clearTimeout(this.timeout);
    var mediaFileArray = [];
    this.setState({ timeResult: mediaArray });
    var startTime = this.props.timeResult[0].start_time;
     var endTime = this.props.timeResult[this.props.timeResult.length - 1].end_time;
    //this.setMediaFile(mediaArray[0].surah.audio_file_name,startTime,startTime)
    var file = "https://d2tpts2trcxfle.cloudfront.net/04 " +mediaArray[0].surah.audio_file_name + ".mp3";
    this.props.store.dispatch(actions.clearMedia("AudioPlayer"));
    var media = {
      sources: {
        mp3:file,
      },
    };
    this.props.store.dispatch(actions.setMedia("AudioPlayer", media));
    this.setState({ startTime: Math.ceil(startTime) });
    this.setState({ initialStartTime: startTime });
    this.setState({ totalPlayed: 0 });
    this.setState({ current_surah: mediaArray[0].surah.num,first_surah: mediaArray[0].surah.num,endTime:endTime });
    
    this.setState({ playing_ayat: mediaArray[0].surah.ayat_num });
    this.setState({ pause: false });
    var totalSurah = 0;
    var lastSurah = mediaArray[0].surah.num ;
    var surah_index = 0;
    var surahEndTime = 0;
    var startingTime = 0; 
    
    Object.keys(mediaArray).forEach((itemKey) => {
     
      lastSurah = mediaArray[itemKey].surah.num;
      if(surah_index != mediaArray[itemKey].surah.num){
         startingTime = mediaArray[itemKey].start_time;
         totalSurah++;
        
      }
     
      surahEndTime = mediaArray[itemKey].end_time;
      console.log(mediaArray[itemKey].surah.num+' '+startingTime);
      surah_index = mediaArray[itemKey].surah.num;
      
      if(itemKey > 0){
        mediaFileArray[mediaArray[itemKey].surah.num] = {file:mediaArray[itemKey - 1].surah.audio_file_name,start_time:startingTime,end_time:surahEndTime};
      } 

    });
    setTimeout(() => {
      if(totalSurah > 1){
        var end_time = mediaFileArray[this.state.current_surah].end_time;
      }else{
        var end_time = this.state.endTime;
      }
     console.log(mediaFileArray);
      this.setState({mediaFileArray:mediaFileArray,totalSurah:totalSurah,lastSurah:lastSurah,endTime:end_time});//,endTime:mediaFileArray[this.state.current_surah].end_time
     
    }, 20);
  
  }

  setMediaFile(file,str_time){
    file = "https://d2tpts2trcxfle.cloudfront.net/04 " +file + ".mp3";
    //console.log(file);
    
   
    this.props.store.dispatch(actions.clearMedia("AudioPlayer"));
    var media = {
      sources: {
        mp3:file,
      },
    };
    //this.setState({ startTime: Math.ceil(str_time) });
    this.setState({ initialStartTime: Math.ceil(str_time) });
    this.props.store.dispatch(actions.setMedia("AudioPlayer", media));
   
  }
  highlightAyat() {
  
   console.log('start time ='+this.state.startTime+'  totalPlayed='+this.state.totalPlayed+' current surah end time ='+this.state.endTime);
    this.timeupdate = 0;
    var ayat_selected = false;
    if(this.state.pause === false){
      return false;
    }
    var totalPalyedwithPause = this.state.startTime;
    
    Object.keys(this.props.timeResult).forEach((itemKey) => {
      if(this.state.current_surah == this.props.timeResult[itemKey].surah.num){
        
      if (
        totalPalyedwithPause <= this.props.timeResult[itemKey].end_time &&
        ayat_selected === false
      ) {
        this.playing_ayat = this.props.timeResult[itemKey].ayat_num;
        this.setState({
          playing_ayat: this.props.timeResult[itemKey].ayat_num,
        });
       
        ayat_selected = true;
      }
    }
    });
    
    if (totalPalyedwithPause > 0 && this.state.playAyatOnly === false) {
      setTimeout(this.props.startPlaying("ayat_" + this.playing_ayat+'_'+this.state.current_surah), 5);
    }
  
  }

  incrementSeconds() {
    //console.log(this.state.startTime);
    if (this.state.pause !== false) {
      this.totalPlayed++;
      this.setState({ totalPlayed: this.totalPlayed });
      this.setState({ startTime: this.totalPlayed });
      if(this.state.totalSurah > 1){
        this.checkCurrentAudioFile();
      }
      
    }
  }
  checkCurrentAudioFile(){
    var ArrayObj = this.state.mediaFileArray;
    var totalSurah = this.state.totalSurah;
    //console.log('totalSurah='+totalSurah);
    var firstSurah = this.props.timeResult[0].surah.num;
    var lastSurah =  this.state.lastSurah;;
    var current_surah = this.state.current_surah;
    
    var currentSurahEndTime =this.state.startTime - ArrayObj[current_surah].start_time;
    var currentSurahDuration = ArrayObj[current_surah].end_time - ArrayObj[current_surah].start_time - 1;
    //console.log('total duration ='+currentSurahDuration+'  totalPlayed='+currentSurahEndTime+' current surah end time ='+currentSurahEndTime+' current surah='+current_surah+' last surah '+(lastSurah));
    if(currentSurahEndTime >= currentSurahDuration && lastSurah > current_surah){
      //if(ArrayObj[current_surah + 1].start_time == 0){
      //  var end_time = ArrayObj[current_surah + 1].end_time;
     // }else{
        var end_time = ArrayObj[current_surah + 1].end_time - ArrayObj[current_surah + 1].start_time;
     // }
     
      this.props.store.dispatch(actions.pause("AudioPlayer"));
      this.props.store.dispatch(actions.clearMedia("AudioPlayer"));
     //this.clearAllStates();
     var startTime = ArrayObj[current_surah + 1].start_time;
     this.setMediaFile(ArrayObj[current_surah + 1].file,startTime);
     this.setState({endTime:end_time,current_surah:current_surah + 1,startTime:startTime})
     console.log('current end time='+ArrayObj[current_surah + 1].end_time+" start time "+ArrayObj[current_surah + 1].start_time+' new end time '+end_time);
     clearInterval(this.PlayingCounter);
     clearInterval(this.state.AyatInterval);
     clearTimeout(this.timeout);
     this.setState({totalPlayed:0})
     this.totalPlayed = 0;
     this.playing_ayat = 1;
    setTimeout(() => {
      this.setState({ pause: false });
        this.play();
    }, 20);

    }
   // Object.keys(ArrayObj).forEach((surah) => {
     // var totalPlayed = ArrayObj[current_surah].start_time + this.state.startTime;
    //  if(this.state.current_surah == surah && ArrayObj[surah].start_time){
    //    var totalPlayed = ArrayObj[surah].start_time
    //  }
  //  });
    //if(this.state.startTime > this.props.timeResult[itemKey].start_time){
     // this.setMediaFile(this.props.timeResult[itemKey].surah.audio_file_name,this.props.timeResult[itemKey].start_time)
   // }
  }
  playAyat(ayat_num) {
    var ayatPlaying = false;
    Object.keys(this.props.timeResult).forEach((itemKey) => {
      if (
        this.props.timeResult[itemKey].ayat_num == ayat_num &&
        ayatPlaying == false
      ) {
        var start_time = this.props.timeResult[itemKey].start_time;
        var end_time = this.props.timeResult[itemKey].end_time;

        this.playAyatOnly(start_time, end_time);
        ayatPlaying = true;

        return;
      }
    });
  }
  playAyatOnly(start_time, end_time) {
    this.state.playAyatOnly = true;
    this.props.store.dispatch(actions.play("AudioPlayer", start_time));
    console.log(start_time + "==" + end_time);
    var totalTime = end_time - start_time;
    this.timeout = setTimeout(() => this.pause(), totalTime * 1000);
  }

  componentWillUpdate() {
    //clearInterval(this.PlayingCounter);
    //this.totalPlayed = 0;
    // this.props.startPlaying("ayat__1");
    //this.pause();
  }

  componentWillMount() {
    console.log(this.props.timeResult);

    initializeOptions(this.defaultOptions);
    this.setState({ previously_played: false });

    // this.callQiraatAPI();
  }

  render() {
    return (
      <JPlayer id={this.defaultOptions.id} className="jp-sleek">
        <Audio
          onTimeUpdate={(e) => this.highlightAyat()}
          //  onPause={(e) => this.Stop()}
        />
        <Gui>
          <div className="jp-controls jp-icon-controls">
            <Play>
              <i
                className="fa"
                onClick={() => setTimeout(() => this.play(), 50)}
              >
                {/* Icon set in css */}
              </i>
            </Play>
            {/* <div><a href="#" onClick={() => setTimeout(() => this.play(), 50)}>Play</a></div>
            <div><a href="#" onClick={() => setTimeout(() => this.Stop(), 10)}>Pause</a></div> */}
            <div className="jp-volume-container">
              <Mute>
                <i className="fa">{/* Icon set in css */}</i>
              </Mute>
              <div className="jp-volume-slider">
                <div className="jp-volume-bar-container">
                  <VolumeBar />
                </div>
              </div>
            </div>
            {/* <FullScreen><i className="fa fa-expand" /></FullScreen> */}
            {/* <Download><i className="fa fa-download" /></Download> */}
            {/* <div className="jp-title-container">
          <Poster />
          <Title />
        </div> */}
          </div>
          <BrowserUnsupported />
        </Gui>
      </JPlayer>
    );
  }
}
export default AudioPlayer;
